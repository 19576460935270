import {
  FaCode,
  FaUnity,
  FaCubes,
  FaDatabase,
  FaBriefcase,
  FaJava,
  FaChevronUp,
  FaChevronDown,
  FaVideo,
  FaBell,
  FaDownload, FaMedal,
  FaChevronRight,
  FaArrowRight,
  FaChevronCircleRight,
  FaChevronCircleLeft,
  FaSignal
} from "react-icons/fa";
import { FaUpRightFromSquare } from "react-icons/fa6";
import { PiCertificate } from "react-icons/pi";
import { RiGameFill, RiRobot2Fill } from "react-icons/ri";
import {
  BsFillBadgeVrFill,
  BsCheck2Circle,
  BsPersonVcard,
  BsInfinity,
  BsFacebook,
  BsLinkedin,
  BsPlayCircle,
  BsArrowUpRight,
  BsPeopleFill,
  BsBook,
  BsClockFill,
  BsCalendar2Day,
  BsSpeedometer2,
  BsPersonVideo2,
  BsSuitcaseLgFill
} from "react-icons/bs";
import {
  SiUnrealengine,
  SiApplearcade,
  SiCodeproject,
  SiCplusplus,
  SiCoursera,
  SiPython,
  SiHtml5,
  SiJavascript,
  SiCsharp,
} from "react-icons/si";
import { GrTechnology, GrCertificate } from "react-icons/gr";
import { GiGamepad, GiTeacher } from "react-icons/gi";
import {
  MdOutlineClose,
  MdOutlineWork,
  MdDesignServices,
  MdOutlineAssignmentTurnedIn,
  MdOutlineQuestionMark,
  MdOutlineQuestionAnswer,
  MdEditDocument,
  MdLockOutline,
  MdRateReview,
  MdOutlineSignalCellularAlt,
  MdOutlineSignalCellularAlt2Bar
} from "react-icons/md";
import { AiOutlineClockCircle, AiFillSafetyCertificate, AiOutlineInstagram } from "react-icons/ai";
import { GoCode, GoDotFill, GoDeviceMobile, GoQuestion } from "react-icons/go";
import { DiScala } from "react-icons/di";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { RxDotFilled } from "react-icons/rx";
import { IoGameController } from "react-icons/io5";

export const iconsList = {
  FaCode,
  FaBell,
  FaUpRightFromSquare,
  FaMedal,
  MdOutlineWork,
  RiGameFill,
  RiRobot2Fill,
  BsFillBadgeVrFill,
  FaUnity,
  SiUnrealengine,
  GrTechnology,
  GrCertificate,
  SiApplearcade,
  GiGamepad,
  MdDesignServices,
  FaCubes,
  FaDatabase,
  BsCheck2Circle,
  MdOutlineClose,
  MdOutlineAssignmentTurnedIn,
  AiOutlineClockCircle,
  FaBriefcase,
  MdOutlineQuestionMark,
  GoCode,
  SiCodeproject,
  HiOutlineArrowNarrowRight,
  GiTeacher,
  BsPersonVcard,
  MdOutlineQuestionAnswer,
  SiCplusplus,
  SiCoursera,
  SiPython,
  SiHtml5,
  SiJavascript,
  SiCsharp,
  FaJava,
  DiScala,
  GoDotFill,
  FaChevronUp,
  FaChevronDown,
  FaVideo,
  FaDownload,
  BsInfinity,
  MdEditDocument,
  AiFillSafetyCertificate,
  GoDeviceMobile,
  AiOutlineInstagram,
  BsFacebook,
  BsLinkedin,
  BsPlayCircle,
  BsArrowUpRight,
  MdLockOutline,
  GoQuestion,
  BsPeopleFill,
  RxDotFilled,
  BsBook,
  BsClockFill,
  IoGameController,
  BsCalendar2Day,
  BsSpeedometer2,
  BsPersonVideo2,
  MdRateReview,
  BsSuitcaseLgFill,
  FaChevronRight,
  FaArrowRight,
  FaChevronCircleLeft,
  FaChevronCircleRight,
  PiCertificate,
  MdOutlineSignalCellularAlt,
  MdOutlineSignalCellularAlt2Bar,
  FaSignal
};
